@use 'sass:math';
@use '../../../_mixin' as m;
/*----------
	top
----------*/

/* KV */
.un-top-billborad {
	$_root: '.un-top-billborad';

	height: 0;
	margin-top: -64px;
	padding-bottom: 150%;
	position: relative;

	&::before {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		bottom: 0;
		z-index: 2;
		background-image: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.7) 100%);
	}

	&__inner {
		@include m.inner-content;
		position: absolute;
		z-index: 2;
		color: #fff;
	}

	&__title {
		max-width: 256px;
		// margin: 0 auto;
		text-indent: -9999px;

		> span {
			display: block;
			width: 100%;
			height: 0;
			padding-bottom: percentage( math.div(141, 245) );
			background: transparent url(/assets/images/top/copy_kv.svg) no-repeat center / contain;
		}
	}

	&__lead {
		margin-top: 32px;
		font-weight: 500;
		font-size: 1.6rem;
		line-height: var(--line-height-m);
	}

	&__button {
		max-width: 408px;
		// height: 54px;
		// margin: 48px auto 0;

		&__inner {
			display: flex;
			align-items: center;
			color: #fff;
			text-decoration: underline;

			span {
				font-size: 1.8rem;
				font-weight: bold;
			}
		}

		&__icon {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 60px;
			height: 60px;
			background: #FFFFFF;
			box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
			color: var(--color-prim);
			font-size: 12px;
			border-radius: 50%;
			margin-left: 20px;
			transform: scale(1);
		}
	}

	&__image {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		opacity: 0;
		transition: opacity 1s linear;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		&.active {
			opacity: 1;
		}
	}

	@include m.mq-sp {
		&__inner {
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		&__title {
			margin: 0 auto;
		}

		&__lead {
			text-align: center;
		}

		&__button {
			margin: 48px auto 0;

			&__inner {
				justify-content: center;
			}
		}
	}

	@include m.mq-pc {
		margin-top: -111px;
		padding-bottom: 800px;

		&__inner {
			bottom: 160px;
			left: 50%;
			z-index: 2;
			transform: translateX(-50%);
			color: #fff;
		}

		&__content {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		&__title {
			max-width: 812px;

			> span {
				padding-bottom: percentage( math.div(58, 802) );
				background-image: url(/assets/images/top/copy_kv_pc.svg);
			}
		}

		&__lead {
			margin-top: 40px;
			font-size: 2.2rem;
			font-weight: 700;
		}

		&__button {
			margin-top: 0;
			margin-left: 40px;

			&__inner {
				span {
					font-size: 2.4rem;
				}
			}

			&__icon {
				width: 100px;
				height: 100px;
				font-size: 20px;
			}

			@media (hover: hover) {
				&__inner {
					&:hover {
						#{$_root}__button__icon {
							transform: scale(1.2);
						}
					}
				}

				&__icon {
					transition: transform .3s ease-out;
				}
			}
		}
	}
}

/* PICKUP */
.un-top-pickup {
	margin-top: -40px;

	&__inner {
		@include m.inner-content;
		position: relative;
		z-index: 3;
	}

	@include m.mq-pc {
		margin-top: -80px;
	}
}

.un-top-subtitle {
	display: inline-block;
	padding: 12px 24px;
	border-left: 5px solid var(--color-prim);
	border-bottom-right-radius: 10px;
	background-color: var(--color-black);
	font-weight: bold;
	font-size: 1.8rem;
	line-height: 1;
	color: #fff;
}

/* 会社概要 */
.un-top-company-link {
	position: relative;

	&__inner {
		height: 134px;
		border-radius: 5px;
		position: relative;
		background: url(/assets/images/top/bg_company.jpg) no-repeat center / cover;
	}

	&__title {
		position: absolute;
		top: 50%;
		right: 30px;
		font-weight: 500;
		font-size: 3.2rem;
		line-height: 1;
		color: #222222;
		transform: translateY(-50%);
	}

	&__links {
		padding: 20px 18px;
		background-color: var(--color-bg);
	}

	@include m.mq-pc {
		&__inner {
			height: 480px;
			background-image: url(/assets/images/top/bg_company_pc.jpg);
		}

		&__title {
			top: 148px;
			right: 108px;
			font-size: 4rem;
		}

		&__links {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 0 50px;
			width: 834px;
			padding: 20px 0 0 50px;
			border-top-left-radius: 5px;
			position: absolute;
			bottom: 0;
			right: 0;
		}
	}
}

/* KV アニメーション */
.kv-fadeup {
	animation-name: kv-fadeup;
	animation-duration: .9s;
	animation-fill-mode: forwards;
	animation-delay: .5s;
	opacity: 0;

	&--2 {
		animation-name: kv-fadeup2;
		animation-delay: 1s;
	}

	&--3 {
		animation-name: kv-fadeup2;
		animation-delay: 1.45s;
	}
}

@keyframes kv-fadeup {
	from {
		opacity: 0;
		transform: translateY(40px);
	}

	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes kv-fadeup2 {
	from {
		opacity: 0;
		transform: translateY(20px);
	}

	to {
		opacity: 1;
		transform: translateY(0);
	}
}
