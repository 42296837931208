@use 'sass:math';

// iconfont vars
$icons: (
	arrow1-r: '\EA01',
	arrow2-b: '\EA02',
	arrow2-l: '\EA03',
	arrow2-r: '\EA04',
	arrow3-b: '\EA05',
	blank: '\EA06',
	check: '\EA07',
	download: '\EA08',
	exclamation: '\EA09',
	global: '\EA0A',
	gotop: '\EA0B',
	key: '\EA0C',
	mail: '\EA0D',
	minus: '\EA0E',
	office: '\EA0F',
	pdf: '\EA10',
	people: '\EA11',
	plus: '\EA12',
	question: '\EA13',
	search: '\EA14',
	snow: '\EA15',
	tag: '\EA16',
	twinkle: '\EA17'
);

// PCビュー
@mixin mq-pc {
	@media (min-width: 768px), print {
		@content;
	}
}

// SPビュー
@mixin mq-sp {
	@media (max-width: 767px) {
		@content;
	}
}

// 基本コンテンツ幅設定
@mixin inner-content {
	width: 100%;
	max-width: 1240px;
	margin-left: auto;
	margin-right: auto;
	padding-left: percentage( math.div(20, 375) );
	padding-right: percentage( math.div(20, 375) );

	@include mq-pc {
		// width: 1240px,
		padding-left: 20px;
		padding-right: 20px;
	}
}

// clrfx
@mixin clrfx {
	&::after {
		content: '';
		display: table;
		clear: both;
	}
}


// ----------
// 便利系（例）
// ----------

// 直接アイコンフォントを設定する一式
@mixin iconfont-default {
	font-family: iconfont !important; /* stylelint-disable-line declaration-no-important,font-family-no-missing-generic-family-keyword */
	speak: none;
	font-style: normal;
	font-weight: normal;
	line-height: 1;
}

// 上下のマージンを強制削除
@mixin margin-trim {
	> *:first-child {
		margin-top: 0 !important; /* stylelint-disable-line declaration-no-important */
	}

	> *:last-child {
		margin-bottom: 0 !important; /* stylelint-disable-line declaration-no-important */
	}
}

@function hex2rgb($color) {
	@return red($color), green($color), blue($color);
}
